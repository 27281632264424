import React, { Component } from "react";
import "./App.scss";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="Footer Responsive-width">
        <p>✌️ I hope you enjoyed. Email derrickhho@gmail.com for more.</p>
      </div>
    );
  }
}

export default Footer;
